import React, { useState } from "react";
import { List, ListItemButton, ListItemText, Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { withStyles } from "@material-ui/core/styles";
const listData = require("./leftNavigationData.json");

const styles = (theme) => ({
  listItem: {
    borderRadius: "1em",
    "&,&:focus": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  //   listItemDone: {
  //     borderRadius: "1em",
  //     backgroundColor: "rgb(138, 0, 229)",
  //     "&,&:focus": {
  //       backgroundColor: "rgb(138, 0, 229)",
  //     },
  //   },
  iconButton: {
    padding: 5,
  },
  important: {
    color: "rgb(138, 0, 229)",
    fontWeight: "bold",
    // backgroundColor: "rgb(138, 0, 229)",
  },
});

// Helper function to update the important property

const updateImportantProperty = (data, currentItem) => {
  data.forEach((item) => {
    if (item.title !== currentItem.title) {
      item.important = false;
    }
    if (item.children && item.children.length > 0) {
      updateImportantProperty(item.children, currentItem);
    }
  });
};

const NestedList = ({ items, classes }) => {
  const [open, setOpen] = useState({});
  const [selected, setSelected] = useState(null);
  const [focused, setFocused] = useState(null);

  const handleClick = (item) => {
    console.log("item clicked", item);
    console.log(" list data", listData);
    // Update the important property for all items
    updateImportantProperty(listData, item);
    if (item.children !== undefined) {
      item.important = false;
    } else {
      item.important = true;
    }
    // item.important = true;
    setOpen((prevOpen) => ({
      ...prevOpen,
      [item.title]: !prevOpen[item.title],
    }));

    if (!item.children || item.children.length === 0) {
      setSelected(item.title);
    } else {
      const hasSelectedChild = item.children.some(
        (child) => selected === child.title
      );
      if (hasSelectedChild) {
        setSelected(null);
      } else {
        setSelected(item.title);
      }
    }

    setFocused(item.title);
  };

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      {items.map((item) => (
        <React.Fragment key={item.title}>
          <ListItemButton
            onClick={() => handleClick(item)}
            className={
              selected === item.title || focused === item.title
                ? classes.listItemDone
                : classes.listItem
            }
            button
            divider>
            <ListItemText
              primary={item.title}
              classes={{ primary: item.important ? classes.important : "" }}
            />
            {item.children &&
              item.children.length > 0 &&
              (open[item.title] ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
          {item.children && item.children.length > 0 && (
            <Collapse in={open[item.title]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <NestedList
                  items={item.children}
                  classes={classes}
                  style={{ paddingLeft: 20 }}
                />
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

const FinNestedList = withStyles(styles)(({ classes }) => {
  return <NestedList items={listData} classes={classes} />;
});

export default FinNestedList;
