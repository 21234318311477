import { React, useState } from "react";
import Loader from "../Loader/Loader";
import ImageSvgs from "../ImageSvgs";
import ResponsiveGrid from "../CommonComponents/ResponsivePageLayout/ResponsiveGrid";
import Button from "react-bootstrap/Button";
import "./FinergyLearningPage.scss";
// import FinLerningLeftNav from "./FinLerningLeftNav";
import FinNestedList from "./FinNestedList";

const data = require("./learningData.json");

const FinergyLearningPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [totalPages, setTotalPages] = useState(1000);
  const [activeBtn, setActiveBtn] = useState("cardView");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const cardFooter = () => {
    return (
      <div className="d-flex justify-content-end">
        <ImageSvgs name="redirect_btn" />
      </div>
    );
  };

  const handleButtonClick = (buttonId) => {
    setActiveBtn(buttonId);
  };

  const renderButton = (buttonId, label) => (
    <Button
      className={`form-select-btn d-flex flex-row justify-content-center w-25 ${
        activeBtn === buttonId ? "form-select-activeBtn" : ""
      }`}
      onClick={() => handleButtonClick(buttonId)}>
      <div>{label}</div>
    </Button>
  );

  return (
    <>
      <div className="d-flex w-100">
        {data?.learningData ? (
          <>
            <div className="w-25 left-border">
              {/* <FinLerningLeftNav /> */}
              {<FinNestedList />}
            </div>
            <div className="w-75">
              <ResponsiveGrid
                buttonTitle={"Add Trainings"}
                inputPlaceholder={"Search Courses..."}
                cardData={data.learningData}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                cardFooter={cardFooter}
                firstViewTitle={renderButton("cardView", "Card")}
                secondViewTitle={renderButton("listView", "Table")}
              />
            </div>
          </>
        ) : (
          <div className="custom-loader">
            <Loader />
          </div>
        )}
      </div>
    </>
  );
};

export default FinergyLearningPage;
