import React, { useState } from "react";
// import ButtonGroup from "react-bootstrap/ButtonGroup";
import ResponsivePagination from "react-responsive-pagination";
import {
  Container,
  Row,
  Col,
  Nav,
  Button,
  Offcanvas,
  ButtonGroup,
} from "react-bootstrap";
import GenericCard from "../../Cards/GenericCard";
import { getBaseUrl } from "../../../config/environment";
import GenericFilter from "../GenericFilter/GenericFilter";
import { EmployeeModal } from "../EmployeeModal";
import "./ResponsiveGrid.scss";

const cardDetailsPage = (props) => {
  return (
    <div>
      <Offcanvas
        show={props.show}
        onHide={props.hide}
        placement="end"
        sm={12}
        lg={12}
        className="card-details"
      >
        <Offcanvas.Body>
          <h1> {props.pageTitle}</h1>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

const ResponsiveGrid = (props) => {
  const [showCardDetails, setShowCardDetails] = useState(false);
  // eslint-disable-next-line
  const [value, setValue] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [employee, setEmployee] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [dropdownShow, setDropdownShow] = useState(false);

  const options = [
    { type: "hierarchy", name: "Actuals GS BA CI update V2", id: 1 },
    { type: "hierarchy", name: "Actuals GS D", id: 2 },
    { type: "hierarchy", name: "Actuals GS D GCO", id: 3 },
    { type: "hierarchy", name: "Actuals GS D GCO Germany", id: 4 },
    { type: "hierarchy", name: "Actuals GS D GCO MGT", id: 5 },
  ];

  const handleCardClick = (id) => {
    setShowCardDetails(true);
  };

  //* for generic dropdown testing(will be removed during actual development)
  const handleModal = () => {
    setShowModal(true);
  };

  const handleDropdownChange = (value) => {
    //! set the dropdown value here
    // setValue(value);
    // console.log("value", value);
  };

  const handleSingleSelectChange = (value) => {
    // console.log("value of single select", value);
  };

  const handleTypeaheadChange = (value) => {
    // console.log("value of typeahead", value);
  };

  const handleInputTypeChange = (text) => {
    // console.log("text ", text);
  };

  const handleInputChange = (e) => {
    let input = e.target.value;
    setInputValue(input);
    // console.log("text of input box", input);
  };

  const handleClear = () => {
    // console.log("Clear the values");
  };

  const handleApply = (values) => {
    setDropdownShow(false);
  };

  const handleCancel = () => {
    setDropdownShow(false);
  };

  const dropdowns = [
    {
      inputType: "multi-select",
      options: options,
      queryKey: "multiSelectQuery",
      placeholder: "MultiSelect dropdown",
      multi: true,
      onChange: handleDropdownChange,
      selected: value,
      label: "Multi select Dropdown",
    },
    {
      inputType: "multi-select",
      options: options,
      queryKey: "singleSelectQuery",
      placeholder: "SingleSelect dropdown",
      multi: false,
      onChange: handleSingleSelectChange,
      selected: value,
      label: "Single select Dropdown",
    },
    {
      inputType: "typeahead",
      inputData: options,
      queryKey: "typeQuery",
      placeholder: "Typeahead Component",
      multi: true,
      options: options,
      onChange: handleTypeaheadChange,
      selected: value,
      onInputChange: handleInputTypeChange,
      label: "Typeahead",
    },
    {
      inputType: "text",
      inputData: {},
      queryKey: "input",
      placeholder: "Input box",
      onChange: handleInputChange,
      value: inputValue,
      label: "Input Box",
    },
    {
      inputType: "employee-search",
      inputData: {},
      queryKey: "employee",
      placeholder: "Search for Employee",
      onChange: setEmployee,
      value: employee,
      onButtonClick: handleModal,
      label: "Employee Search",
    },
  ];

  return (
    <div>
      <div className="main-layout-container">
        <Container fluid className="mx-2 me-3 options-container">
          <Row>
            <Col
              xs={6}
              xl={6}
              className="d-flex justify-content-start w-50 p-0"
            >
              <ButtonGroup className="my-2 w-75">
                {/* // Add the buttons here */}
                {/* <Button> {props.firstViewTitle} </Button>
                <Button> {props.secondViewTitle}</Button> */}
                {props.firstViewTitle}
                {props.secondViewTitle}
              </ButtonGroup>
            </Col>
            <Col xs={6} xl={6} className="d-flex justify-content-end w-50 p-0">
              <div className="d-flex pt-1">
                <Nav.Link className="my-auto">
                  <Button className="app-tertiary-btn">
                    {props.buttonTitle}
                  </Button>
                </Nav.Link>

                <div className="app-primary-input-field custom-search pt-1 ms-3">
                  <input
                    type="search"
                    id="message"
                    placeholder={props.inputPlaceholder}
                  />
                </div>

                <GenericFilter
                  show={dropdownShow}
                  onToggle={() => setDropdownShow(false)}
                  onSelect={() => setDropdownShow(!dropdownShow)}
                  dropdowns={dropdowns}
                  onApply={handleApply}
                  onCancel={handleCancel}
                  onClear={handleClear}
                  title="Generic Advance Filters"
                />
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid className="mt-4">
          <div className="row">
            {props.cardData.map((data, index) => (
              <div
                key={data.id}
                className="col-12 col-sm-5 col-md-4 col-lg-3 col-xl-2 mb-4"
              >
                <GenericCard
                  id={data.id?.toString()}
                  header={data.attributes.title}
                  description={data.attributes.desc}
                  overlayTriggers={{
                    header: data.attributes.title,
                    description: data.attributes.desc,
                  }}
                  imageSrc={`${getBaseUrl()}/images/default-images/default-news.jpg`}
                  isHover={true}
                  onClick={() => handleCardClick()}
                  footer={props.cardFooter()}
                />
              </div>
            ))}
          </div>
        </Container>

        <footer className="footer">
          <div className="d-flex justify-content-end">
            <ResponsivePagination
              current={props.currentPage}
              total={props.totalPages}
              onPageChange={props.onPageChange}
            />
          </div>
        </footer>
      </div>
      {showCardDetails &&
        cardDetailsPage({
          show: showCardDetails,
          hide: () => setShowCardDetails(false),
          pageTitle: "Card Details",
        })}

      {showModal && (
        <EmployeeModal
          show={showModal}
          type={"owners"}
          handleClose={() => setShowModal(false)}
          // getMembers={processDeputy}
          // getExisting={processOwner}
          title={"Add Deputy To Process"}
          // setMember={setProcessDeputy}
          buttonTitle={"Add Deputies"}
        />
      )}
    </div>
  );
};

export default ResponsiveGrid;
