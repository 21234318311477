import React from "react";
import { Route } from "react-router-dom";
import FinergyDashboard from "../components/FinergyComponents/FinergyDashboard";
import FinergyLearningPage from "../components/FinergyComponents/FinergyLearningPage";
import FinergyOverviewPage from "../components/FinergyComponents/FinergyOverviewPage";

const FinergyRoutes = () => {
  return (
    <React.Fragment>
      <Route path="/landscape/finergy" element={<FinergyDashboard />}>
        <Route path="overview" element={<FinergyOverviewPage />} />
        <Route path="learning" element={<FinergyLearningPage />} />
      </Route>
    </React.Fragment>
  );
};

export default FinergyRoutes;
